import React, { Component } from "react";

import "./UserMarker.css";

export default class UserMarker extends Component {
  static propTypes = {};
  render() {
    return (
      <div className="UserMarker">
        <div className="Inner"></div>
      </div>
    );
  }
}
